<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
  >
    <g
        fill="none"
        fill-rule="nonzero"
    >
      <path
          class="stroke-current"
          stroke-width="1.5"
          d="M30 7c.748 0 2 1.205 2 3.587V19h-4"
      />
      <path
          class="stroke-current"
          stroke-width="1.5"
          d="M29.983 7H10.875C10.18 7 9 8.193 9 10.519V34l9.593-5.595L28.195
          34V10.519C28.195 8.193 29.317 7 29.983 7z"
      />
      <path
          class="fill-current"
          d="M13.201 18H24v1.5H13.201z"
      />
      <path
          class="fill-current"
          d="M19.35 13.35v10.8h-1.5v-10.8z"
      />
    </g>
  </svg>

</template>


<script>
export default {};
</script>


<style lang="scss" scoped>

</style>
