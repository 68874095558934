<template>
  <UiModal 
    :is-visible.sync="modalIsVisible"
    :is-close-area="isCloseArea"
  >
    <template slot="header">
      <h3 class="font-sans-bold text-base text-faded-red">
        {{ $t(`bookmarks.modal.${activeModalTypeTranslationKey}.title_text`) }}
      </h3>
    </template>
    <div
        v-if="modalTypeIsNewBookmarkListFromSaveBookmark"
        tabindex="0"
        class="inline-flex items-center mb-18 cursor-pointer"
        @click="onBackToSaveBookmarkedProductPress"
        @keydown.space="onBackToSaveBookmarkedProductPress"
    >
      <IconArrow
          direction="left"
          class="text-faded-red"
      />
      <p class="text-style-7 ml-10">
        {{ $t('bookmarks.modal.new_bookmark_list.back_to_save_bookmarked_product_text') }}
      </p>
    </div>
    <p class="text-style-7 mb-35">
      {{ modalOptions.description }}
    </p>
    <BookmarkForm
        ref="bookmark-form"
        :product="product"
        :submit-button-label="modalOptions.buttonLabel"
    />
  </UiModal>
</template>


<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import UiModal from '@/components/ui/UiModal';
import IconArrow from '@/components/icon/IconArrow';
import BookmarkForm from './BookmarkForm';


export default {
  props: {
    product: Object,
  },
  components: {
    UiModal,
    IconArrow,
    BookmarkForm,
  },
  data: () => ({
    modalIsVisible: false,
    successMessageIsVisible: false,
    formValidationMessage: '',
    isCloseArea: true,
  }),
  watch: {
    'uiModal.isVisible': {
      immediate: true,
      handler(newValue) {
        if (!this.modalTypeIsNewBookmarkList
          && !this.modalTypeIsRenameBookmarkList
          && !this.modalTypeIsDeleteBookmarkList
          && !this.modalTypeIsSaveBookmarkedProduct) return;
        this.modalIsVisible = newValue;
      },
    },
    modalIsVisible: {
      handler(newValue) {
        this.updateModalState({ isVisible: newValue });
      },
    },
  },
  computed: {
    ...mapState('ui', {
      uiModal: 'modal',
      uiSelectedBookmarkList: 'selectedBookmarkListView',
    }),
    ...mapGetters('ui', {
      modalTypeIsNewBookmarkList: 'modalTypeIsNewBookmarkList',
      modalTypeIsRenameBookmarkList: 'modalTypeIsRenameBookmarkList',
      modalTypeIsDeleteBookmarkList: 'modalTypeIsDeleteBookmarkList',
      modalTypeIsSaveBookmarkedProduct: 'modalTypeIsSaveBookmarkedProduct',
      modalTypeIsNewBookmarkListFromSaveBookmark: 'modalTypeIsNewBookmarkListFromSaveBookmark',
    }),
    activeModalTypeTranslationKey() {
      if (this.modalTypeIsNewBookmarkList) return 'new_bookmark_list';
      if (this.modalTypeIsRenameBookmarkList) return 'rename_bookmark_list';
      if (this.modalTypeIsDeleteBookmarkList) return 'delete_bookmark_list';
      return 'save_bookmarked_product';
    },
    modalOptions() {
      // bookmarkListName
      let descriptionTextOption = {};
      if (this.modalTypeIsDeleteBookmarkList) {
        descriptionTextOption = { bookmarkListName: this.uiSelectedBookmarkList.name };
      }

      return {
        title: this.$t(`bookmarks.modal.${this.activeModalTypeTranslationKey}.title_text`),
        description: this.$t(`bookmarks.modal.${this.activeModalTypeTranslationKey}.description_text`, descriptionTextOption),
        buttonLabel: this.$t(`bookmarks.modal.${this.activeModalTypeTranslationKey}.button_text`),
      };
    },
  },
  methods: {
    ...mapActions('ui', {
      updateModalState: 'updateModalState',
    }),
    onBackToSaveBookmarkedProductPress() {
      this.updateModalState({ isVisible: true, type: 'save-bookmarked-product' });
    },
  },
};
</script>


<style lang="scss" scoped>

</style>
