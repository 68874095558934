<template>
  <form novalidate>
    <UiTextInput
        v-if="modalTypeIsNewBookmarkList
        || modalTypeIsNewBookmarkListFromSaveBookmark
        || modalTypeIsRenameBookmarkList"
        v-model="formData.name"
        name="name"
        autocomplete="true"
        :placeholder="$t('bookmarks.modal.form.name.placeholder_text')"
        :no-icons="true"
        :rules="formRules.name"
        class="mb-7"
    />
    <div
      v-if="modalTypeIsSaveBookmarkedProduct"
      class="list-reset"
    >
      <UiCheckbox
        v-for="(bookmarkList, index) in bookmarkListOptions"
        :key="`form-bookmark-list-${index}`"
        v-model="selectedBookmarkListsIds"
        :val="bookmarkList.id"
        class="mb-16"
        @update-bookmark-list="onBookmarkListPress(bookmarkList.id || '', bookmarkList.name)"
      >
        <template>
          {{ bookmarkList.name }}
        </template>
      </UiCheckbox>
      <div
          tabindex="0"
          class="bookmark-list flex items-center flex-start text-style-7 mb-23 select-none
            cursor-pointer outline-none hover:text-faded-red focus:text-faded-red"
          @click="onNewBookmarkListPress"
          @keydown.space="onNewBookmarkListPress"
      >
        <IconCross
            :rotation-angle="45"
            class="mr-15"
        />
        <p>
          {{ $t('bookmarks.new_list_text') }}
        </p>
      </div>
    </div>
    <UiButton
        class="w-full font-sans-bold mt-14 py-15 text-pure-white bg-charcoal-grey uppercase"
        type="submit"
        @click.native.prevent="onFormSubmission"
        @keydown.space.native.prevent="onFormSubmission"
    >
      {{ submitButtonLabel }}
    </UiButton>
    <TransitionCollapse>
      <p
          v-if="formValidationMessage"
          class="text-sm text-faded-red leading-43"
      >
        {{ formValidationMessage }}
      </p>
    </TransitionCollapse>
  </form>
</template>


<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { cloneDeep } from 'lodash';
import TransitionCollapse from '@/components/transition/TransitionCollapse';
import IconCross from '@/components/icon/IconCross';
import UiButton from '@/components/ui/UiButton';
import UiRadio from '@/components/ui/UiRadio';
import UiCheckbox from '@/components/ui/UiCheckbox';
import UiTextInput from '@/components/ui/UiTextInput';


export default {
  props: {
    product: Object,
    submitButtonLabel: {
      type: String,
      default: '',
    },
  },
  components: {
    TransitionCollapse,
    IconCross,
    UiButton,
    UiRadio,
    UiCheckbox,
    UiTextInput,
  },
  data() {
    let selectedBookmarkListsIds = [];
    // if product ID is available select the bookmarkList in which the product is
    if (this.product && this.product.id) {
      const existingBookmarkedProduct = this.$store.state.user.bookmarks.bookmarkedProducts
        .find(bookmarkedProduct => bookmarkedProduct.id === this.product.id);

      // If product is bookmarked already
      if (existingBookmarkedProduct
        && existingBookmarkedProduct.data.bookmarkListIds.length !== 0) {
        selectedBookmarkListsIds = [...existingBookmarkedProduct.data.bookmarkListIds];
        existingBookmarkedProduct.data.bookmarkListIds.forEach(id => {
          this.updateSelectedBookmarkList({ id });
        })
      } else if (!this.$store.state.ui.selectedBookmarksLists.length) {
        this.$store.state.ui.selectedBookmarksLists.forEach(list => selectedBookmarkListsIds.push(list.id));
      }
    }
    return {
      formValidationMessage: '',
      formData: cloneDeep(this.$store.state.ui.bookmarkFormData),
      formRules: {
        name: [{
          message: 'bookmarks.modal.form.name.is_empty_text',
          validationFunction: value => value !== '' && value.length <= 256,
          trigger: 'submit',
        }],
      },
      selectedBookmarkListsIds,
    };
  },
  watch: {
    formData: {
      deep: true,
      handler(newValue) {
        this.updateBookmarkFormData(cloneDeep(newValue));
      },
    },
  },
  computed: {
    ...mapState('user/bookmarks', {
      userBookmarksBookmarkedProducts: 'bookmarkedProducts',
    }),
    ...mapGetters('ui', {
      modalTypeIsNewBookmarkList: 'modalTypeIsNewBookmarkList',
      modalTypeIsRenameBookmarkList: 'modalTypeIsRenameBookmarkList',
      modalTypeIsDeleteBookmarkList: 'modalTypeIsDeleteBookmarkList',
      modalTypeIsSaveBookmarkedProduct: 'modalTypeIsSaveBookmarkedProduct',
      modalTypeIsNewBookmarkListFromSaveBookmark: 'modalTypeIsNewBookmarkListFromSaveBookmark',
    }),
    ...mapGetters('user/bookmarks', {
      bookmarkListOptions: 'bookmarkListOptions',
      selectedBookmarkListOption: 'selectedBookmarkListOption',
    }),
  },
  methods: {
    ...mapActions('ui', {
      updateModalState: 'updateModalState',
      updateBookmarkFormData: 'updateBookmarkFormData',
      updateSelectedBookmarkList: 'updateSelectedBookmarkList',
    }),
    ...mapActions('user/bookmarks', {
      FSAddBookmarkListDocument: 'FSAddBookmarkListDocument',
      FSUpdateBookmarkListDocument: 'FSUpdateBookmarkListDocument',
      FSDeleteBookmarkListDocument: 'FSDeleteBookmarkListDocument',
      FSSetBookmarkedProductDocument: 'FSSetBookmarkedProductDocument',
    }),
    onNewBookmarkListPress() {
      this.updateModalState({ isVisible: true, type: 'new-bookmark-list-from-save-bookmarked-product' });
    },
    onBookmarkListPress(id, name) {
      this.updateSelectedBookmarkList({ id, name });
    },
    async validateFormInputs() {
      const inputValidators = [];

      this.$children.forEach((child) => {
        if (child.validateInput !== undefined) {
          inputValidators.push(child.validateInput());
        }
      });

      return Promise.all(inputValidators)
        .then(() => Promise.resolve())
        .catch(() => Promise.reject());
    },
    async onFormSubmission() {
      this.formValidationMessage = '';

      try {
        await this.validateFormInputs();

        if (this.modalTypeIsNewBookmarkList) {
          await this.FSAddBookmarkListDocument();
          this.formData.name = '';
        } else if (this.modalTypeIsNewBookmarkListFromSaveBookmark) {
          const { id, name } = await this.FSAddBookmarkListDocument();

          this.formData.name = '';
          this.updateSelectedBookmarkList({ id, name });
          this.updateModalState({ isVisible: true, type: 'save-bookmarked-product' });

          return;
        } else if (this.modalTypeIsRenameBookmarkList) {
          await this.FSUpdateBookmarkListDocument();
          this.formData.name = '';
        } else if (this.modalTypeIsDeleteBookmarkList) {
          const bookmarkedProductIds = this.selectedBookmarkListOption.products
            .map(product => product.id);
          const listId = this.selectedBookmarkListOption.id;
          await this.FSDeleteBookmarkListDocument({ bookmarkedProductIds, listId });
        } else if (this.modalTypeIsSaveBookmarkedProduct) {
          await this.FSSetBookmarkedProductDocument(this.product);
        }

        this.updateModalState({ isVisible: false });
      } catch (error) {
        if (!error || !error.message) return;
        this.formValidationMessage = error.message;
      }
    },
  },
};
</script>


<style lang="scss" scoped>

</style>
