<template>
  <label
      role="radio"
      :aria-checked="isSelected"
      :tabindex="0"
      ref="label"
      class="ui-radio flex items-center cursor-pointer focus:outline-none"
      @keydown.space.stop.prevent="onChange"
      @mousedown.prevent
  >
    <input
        v-bind="$attrs"
        type="checkbox"
        :value="val"
        :checked="isSelected"
        tabindex="-1"
        class="ui-input absolute w-23 h-23 opacity-0"
        @change="onChange"
    >
    <span class="circle relative border border-pinkish-grey rounded-50p">
      <Transition name="zoom-in-center">
        <span
            v-show="isSelected"
            class="inner-circle absolute pin m-auto w-15 h-15 bg-faded-red rounded-50p"
        ></span>
      </Transition>
    </span>
    <span
        class="label inline-block flex-shrink align-middle text-style-7 ml-15 select-none"
        :class="{
            'text-charcoal-grey': isSelected,
        }"
    >
      {{ label }}
    </span>
  </label>
</template>


<script>
import IconCheckbox from '@/components/icon/IconCheckbox';
import { isString } from 'lodash';


export default {
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: '',
    },
    val: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '-',
    },
  },
  components: {
    IconCheckbox,
  },
  computed: {
    isSelected() {
      return this.value === this.val;
    },
  },
  methods: {
    onChange() {
      this.$emit('input', this.val);
    },
    isString(value) {
      return isString(value);
    },
  },
};
</script>


<style lang="scss" scoped>
$circle-size: 25px;
$inner-circle-size: 13px;


.ui-radio {
  .circle {
    flex: 0 0 $circle-size;
    height: $circle-size;
    transition: border 0.3s;

    .inner-circle {
      width: $inner-circle-size;
      height: $inner-circle-size;
    }
  }
}


// Handling environments that have a precise pointing device
@media (any-pointer: fine) {
  .ui-radio {
    &:focus .circle,
    &:hover .circle {
      @apply border-charcoal-grey;
    }
  }
}

</style>
